<template>
  <div class="v2-container">
    <div class="quote-tool-wrapper-v2">
      <div class="mb-4 pt-4">
        <h2 class="text-center">{{ config.title }}</h2>
        <h5 class="text-center px-5">
          {{ config.subtitle }}
        </h5>
      </div>
      <v-stepper
        :vertical="useVerticalStepper"
        :key="useVerticalStepper"
        v-model="currentStep"
        flat
      >
        <template v-if="useVerticalStepper">
          <template v-for="(item, id) in publicSteps">
            <v-stepper-step
              v-if="!item.hiddenTitle"
              :key="`${id + 1}`"
              :complete="currentStep > id + 1"
              :step="id + 1"
              :editable="currentStep > id + 1 && !quote.created"
              :color="currentStep > id + 1 ? 'success' : 'secondary'"
              :edit-icon="item.icon || 'mdi-check'"
              :complete-icon="item.icon || 'mdi-check'"
            >
              {{ item.title }}
              <template v-if="item.subtitle">
                <small v-html="item.subtitle" />
              </template>
            </v-stepper-step>
            <v-stepper-content :key="`vert-${id}`" :step="id + 1">
              <v-progress-linear
                v-if="currentStep === id + 1"
                :active="loading > 0"
                :indeterminate="loading > 0"
                color="accent"
                id="loading"
              ></v-progress-linear>
              <component
                v-if="currentStep > id"
                :is="item.component"
                :stepKey="id + 1"
                :nextStep="nextStep"
                :previousStep="id !== 0 ? previousStep : undefined"
                :stepObject="item"
              ></component>
            </v-stepper-content>
          </template>
        </template>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import LocationStep from "./steps-v2/Location";
import CustomerStep from "./steps-v2/Customer";
import ServiceStep from "./steps-v2/Service";
import DateStep from "./steps-v2/Date";
import FinishStep from "./steps-v2/Finish";
import Review from "./steps-v2/Review";
import ProjectTypeStep from "./steps-v2/ProjectType";
import MaterialTypeStep from "./steps-v2/MaterialType";
import ContainerSizeStep from "./steps-v2/ContainerSize";
import { createNamespacedHelpers } from "vuex";
import QuoteSection from "./utils/QuoteSection.vue";
import { mapFields } from "vuex-map-fields";
const {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} = createNamespacedHelpers("quotes");

export default {
  name: "QuoteToolV2",
  components: {
    QuoteSection,
    LocationStep,
    CustomerStep,
    DateStep,
    FinishStep,
    Review,
    ServiceStep,
    ProjectTypeStep,
    MaterialTypeStep,
    ContainerSizeStep,
    InitialCustomerStep: CustomerStep
  },
  data: () => ({
    loadingCustomer: false
  }),
  props: ["config"],
  computed: {
    ...mapFields("quotes", ["completedSteps"]),
    ...mapGetters([
      "userAccess",
      "publicSteps",
      "userAccess",
      "isInitialized",
      "customerData"
    ]),
    ...mapState(["loading", "messages", "quote"]),
    ...mapState({
      cStep: state => state.currentStep
    }),
    currentStep: {
      get() {
        return this.cStep;
      },
      set(value) {
        this.SET_STEP(value);
      }
    },
    useVerticalStepper() {
      //return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
      return true;
    },
    currentStepObject() {
      return this.publicSteps[this.currentStep - 1];
    }
  },
  methods: {
    ...mapMutations(["SET_STEP"]),
    ...mapActions(["updateBaseData", "createQuote", "processQuotePayment"]),
    updateQid() {
      const qid =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);
      window.localStorage.setItem("ro360-qid", qid);
    },
    nextStep() {
      this.currentStep++;
    },
    previousStep() {
      this.currentStep--;
    }
  },
  mounted() {
    // Load base data
    this.$store.dispatch("quotes/updateBaseData");

    if (!window.localStorage.getItem("ro360-qid")) {
      // Generate random ID
      this.updateQid();
    }

    this.$store.dispatch("quotes/logQuote", {
      action: "initialized",
      qid: window.localStorage.getItem("ro360-qid")
    });
  },
  watch: {
    config: {
      handler() {
        this.$store.commit("quotes/SET_CONFIG", this.config);
      },
      deep: true
    },
    currentStep(value) {
      // Check if there was a previous step, and if so, add it's component name to completedSteps
      if (value > 1) {
        const component = this.publicSteps[value - 2].component;

        if (!this.completedSteps.includes(component)) {
          this.completedSteps = [...this.completedSteps, component];
        }

        this.$store.dispatch("quotes/logAnalyticsEvent");
      }
      this.$store.dispatch("quotes/logQuote", {
        action: "step_change",
        data: { step: value, form: this.customerData },
        qid: window.localStorage.getItem("ro360-qid")
      });

      setTimeout(() => {
        if (this.currentStepObject.component === "ServiceStep") {
          if (!this.quote.created) {
            this.createQuote(true).then(() => {
              // Log analytics event for "soft lead".
              this.$store.dispatch("quotes/logAnalyticsEvent", {
                event: "soft_lead",
                params: {
                  event_label: "Soft Lead Created"
                }
              });
            });
          }
        }
        if (this.publicSteps.length && value === this.publicSteps.length) {
          if (!this.quote.created || this.quote.service.id) {
            this.createQuote(this.quote.purchaseService).then(() => {
              // Update QID
              this.updateQid();

              // Log analytics event for "quote_created".
              this.$store.dispatch("quotes/logAnalyticsEvent", {
                event: "quote_created",
                params: {
                  event_label: "Quote Created"
                }
              });

              if (this.quote.purchaseService) {
                this.processQuotePayment().then(() => {
                  // Log analytics event for "quote_paid".
                  this.$store.dispatch("quotes/logAnalyticsEvent", {
                    event: "quote_paid",
                    params: {
                      event_label: "Quote Paid"
                    }
                  });
                });
              }
            });
          } else if (this.quote.purchaseService) {
            this.processQuotePayment().then(() => {
              // Log analytics event for "quote_paid".
              this.$store.dispatch("quotes/logAnalyticsEvent", {
                event: "quote_paid",
                params: {
                  event_label: "Quote Paid"
                }
              });
            });
          }
        }
      }, 500);
    }
  }
};
</script>
<style>
.quote-tool-step-content {
  padding: 5px;
}
.v2-container {
  max-width: 100%;
  min-height: 100vh;
  background-color: white;
}

.quote-tool-wrapper-v2 {
  margin: auto;
  background-color: #fff;
  min-height: 100vh;
  max-width: 800px;
  @media screen and (max-width: 600px) {
    margin: 0;
    max-width: 100%;
  }
}
</style>
