<template>
  <v-app :id="elId">
    <v-system-bar
      v-if="!config.hideTopTitleBar"
      app
      height="35"
      color="grey darken-4"
      :lights-out="true"
      dark
    >
      <v-spacer></v-spacer>
      <v-toolbar-title class="subtitle-1 d-flex">
        {{ config.topTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-system-bar>
    <v-app-bar app :color="config.headerColor || 'brand'">
      <v-btn icon :href="`mailto:support@nextdaydumpsters.com`">
        <v-icon color="primary">mdi-email</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex">
        <img
          class="mx-auto pa-2"
          :height="50"
          src="@/assets/logo_wide.png"
          alt=""
        />
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon :href="`tel:8009546398`">
        <v-icon color="primary">mdi-phone</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <QuoteToolV2 :config="config" />
    </v-main>
  </v-app>
</template>

<script>
import env from "./env";
import { mapGetters, mapState } from "vuex";
import QuoteToolV2 from "./components/quote-tool/QuoteToolV2.vue";
import loadGoogleAnalytics from "@/utils/google-analytics";

export default {
  name: "AppQuoteToolV2",
  components: { QuoteToolV2 },
  data: () => ({
    config: {},
    elId: env.el,
    developer: env.DEVELOPER_MODE,
    viewport: {
      title: "Laptop",
      icon: "mdi-laptop",
      width: "100%",
      height: "100vh"
    },
    viewports: [
      {
        title: "Phone",
        icon: "mdi-cellphone",
        width: "375px",
        height: "812px"
      },
      {
        title: "Tablet",
        icon: "mdi-tablet",
        width: "1024px",
        height: "1366px"
      },
      { title: "Laptop", icon: "mdi-laptop", width: "100%", height: "100vh" }
    ]
  }),
  computed: {
    ...mapGetters("quotes", ["userAccess"]),
    ...mapState("quotes", ["currentConfig", "defaultConfig"])
  },
  mounted() {
    this.config = this.defaultConfig;
    loadGoogleAnalytics("G-F43GXBF32T");
    document.title = 'Reliable Roll-Off Dumpster Rental | Next Day Dumpster - Quote Tool';
  },
  
};
</script>
<style>
#viewport {
  margin: auto;
  padding: 0;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
}
/* Embed Specific Style Overrides */
.v-application--wrap {
  min-height: 0vh !important;
  background-color: rgba(33, 33, 33, 0.3) !important;
}

[aria-busy="true"] {
  cursor: auto;
}

.container:first-of-type {
  padding: 0 !important;
}

@media screen and (max-width: 768px) {
  .v-stepper__content {
    padding: 16px 45px 16px 12px !important;
  }
}

#step-toolbar.v-toolbar .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0;
}

#step-toolbar.v-toolbar .v-btn:not(.v-btn--round).v-size--default {
  padding: 0px 16px !important;
}

.row:not(.v-application--wrap *) {
  margin: 0;
}

#post-1825 .row .entry-content .review_title {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .v-textarea textarea {
    border: none !important;
  }

  #masthead {
    width: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 193;
    background-color: #ffffff;
  }

  .v-stepper__content {
    padding: 10px !important;
    padding-right: 50px !important;
  }

  #post-1825 .row {
    margin-bottom: 0.5rem;
  }

  #post-1825 .row .entry-content {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  #post-1825 .row .entry-content .review_title {
    margin-top: 0;
    font-size: 1.3rem !important;
  }

  #primary {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 0;
  }

  .review_title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 768px) {
  #primary {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
    padding: 0;
  }

  #post-1825 .row .entry-content .review_title {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
</style>
